import React from 'react';
import AppButton from '../CustomComponents/app-button';
import arrowTeal from '../../assets/icons/teal-arrow.svg';
import silicon from "../../assets/news/silicon.png"
import wiki from "../../assets/news/wiki.png"
import accelartion from "../../assets/news/acceleration.png"
import newsImage from '../../assets/use-cases/news-new.png';
import './news.css';

function NewsSection() {
    const title = 'THIS JUST IN';
    const link = "https://twitter.com/Scobleizer/status/1721980340884107568?t=weQF9FpW7MMeZTOh1_VftQ&s=09";
    const description = `Check out the latest interview of Aakrit Prasad (Co-Founder & CEO) and Robert Scoble (American Blogger & Former strategist
        @ Microsoft) on leveraging customer support using industry’s first Answer Engine with AnswerGPT™.`;
    // const description2 = 'In our latest startup conversation, I sat down with two executives of AptEdge, a firm using natural language processing to improve customer service.';
    const tableData = [
        {
            icon: silicon,
            date: 'Aug 16 2023',
            link: 'https://siliconangle.com/2023/08/16/startup-conversation-aptedge-uses-ai-natural-language-processing-streamline-customer-care/ ',
            title:'AptEdge uses AI and natural language processing to streamline customer care', 
            description: 'In our latest startup conversation, I sat down with two executives of AptEdge, a firm using natural language processing to improve customer service.'
        },
        {
            icon: accelartion,
            date: 'Aug 16 2023',
            link: "https://accelerationeconomy.com/ai/aptedge-capitalizes-on-generative-ai-to-transform-customer-service/",
            title: 'AptEdge Capitalizes on Generative AI to Transform Customer Service',
            description: 'AptEdge is one of those companies; it’s moved away from the automated chatbot and adopted generative AI to reimagine customer support, providing the first GPT-powered engine to give customer support agents immediate access to contextual knowledge from across an organization’s applications.'
        },
        {
            icon: wiki,
            date: 'Aug 16 2023',
            link: "https://wikibon.com/startup-aptedge-uses-ai-and-nlp-to-streamline-customer-care/",
            title: 'Startup AptEdge uses AI and NLP to Streamline Customer Care',
            description: 'In our latest startup conversation, I sat down with two executives of AptEdge, a firm using natural language processing to improve customer service. Aakrit Prasad is the CEO and Co-Founder of AptEdge, and industry veteran Bobby Napiltonia, serves as an advisor to AptEdge, an executive with experience from Twilio and Salesforce.'
        },
      
    ];
    return (
        <div>
            <div className="news-container">
                <div className="news-hero-section">
                    <div className="news-text-block">
                        <h1>
                            {title} 
                        </h1>
                        {/* <img src={arrowTeal} alt="arrow-icon" loading="lazy" className="arrow-icon" /> */}
                        <h3 className='news-h3'>{description}</h3>
                        {/* <p>{description2}</p> */}
                        <div className="button-container">


                            <AppButton text="Watch Now" variant="variant-teal" linkTo={link} target={"_blank"} />

                        </div>
                    </div>

                    <div className="news-image">
                        <img src={newsImage} alt="news-image" loading="lazy" className='
                        news-home-img'/>
                           {/* <img src={silicon} alt="arrow-icon" loading="lazy" className='
                        news-home-icon' />  */}
                    </div>
                </div>
            </div>
            <div className="list-container">
                {tableData?.map((data,i) => {
                    return (
                        <div className="box" key={i}>
                            <div className="icon-box">
                                {data.icon ? (
                                    <img src={data.icon} alt="icon" loading="lazy" className="li-logo" />
                                ) : (
                                    <h2 className="li-icon">icon</h2>
                                )}
                            </div>
                            <div className="li-date" >{data.date}</div>
                            <div className="des">
                                <a href={data.link} target="_blank">

                                    <h4>{data.title}</h4>
                                </a>
                                <p>{data.description}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default NewsSection;
