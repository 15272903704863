import { graphql } from 'gatsby';
import React from 'react';
import AppLayout from '../components/AppLayout';
import NewsSection from '../components/news-section';
import '../styles/index.css';
import HeroSection from '../components/components-group-one/hero-section';
import { imageDataType } from './prevent-escalations';

function News({ data }: { data: imageDataType }) {
    const pageData = {
        seoData: {
            title: "AI-Driven Knowledge Orchestration Platform",
            canonicalLink: "https://www.aptedge.io/technology",
            metaDescription: {
                metaDescription: "AptEdge uses its proprietary AI and workflow to orchestrate the disparate knowledge systems used by customer care, IT support, and service desk teams to activate knowledge through search and automation.",
            },
            openGraphImage: {
                file: {
                    url: "",
                },
            },
        },
    }
    return (
        <>
            <AppLayout seoData={pageData.seoData}>

                <NewsSection />
            </AppLayout>
        </>
    );
}

export default News;
